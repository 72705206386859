import Cookies from "js-cookie";
import request from "@/utils/request";
// 新建会议室地点
export function addMeetingAddress(params) {
  return request({
    url: "/meeting/addAddress",
    method: "GET",
    params,
  });
}

//新建会议室
export function addMeeting(data) {
  return request({
    url: "/meeting/addMeeting",
    method: "POST",
    data,
  });
}
//删除会议室
export function delMeeting(params) {
  return request({
    url: `/meeting/del/${params.mrCode}`,
    method: "DELETE",
    params,
  });
}
//新建会议室
export function editMeeting(data) {
  return request({
    url: "/meeting/editMeeting",
    method: "POST",
    data,
  });
}
//预约会议室
export function appointRoom(data) {
  return request({
    url: "/meeting/appointment/appointRoom",
    method: "POST",
    data,
  });
}
// 获取会议室列表
export function meetingRoomList(params) {
  return request({
    url: "/meeting/mrList",
    method: "GET",
    params,
  });
}
//会议室地址字典列表
export function addressList(params) {
  return request({
    url: "/meeting/addressList",
    method: "GET",
    params,
  });
}
//获取可预约会议室列表
export function getAvailableList(params) {
  return request({
    url: "/meeting/getAvailableList",
    method: "GET",
    params,
  });
}
// 取消预约
export function cancleAppointment(params) {
  return request({
    url: `/meeting/appointment/cancle/${params.appoCode}`,
    method: "GET",
    params,
  });
}
//获取我的预约会议室列表 pageNum pageSize
export function myAppointList(params) {
  return request({
    url: "/meeting/appointment/myAppointList",
    method: "GET",
    params,
  });
}
// 结束会议
export function endMeeting(params) {
  return request({
    url: `/meeting/appointment/endMeeting/${params.appoCode}`,
    method: "GET",
    params,
  });
}
//会议详情
export function appointmentInfo(params) {
  return request({
    url: `/meeting/appointment/info/${params.appoCode}`,
    method: "GET",
    params,
  });
}
//获取我的预约会议室列表 pageNum pageSize
export function partMeetingList(params) {
  return request({
    url: "/meeting/appointment/partMeetingList",
    method: "GET",
    params,
  });
}
//上传图片
export function uploadImg(data) {
  return request({
    url: "/cdn/upload/uploadImg",
    method: "POST",
    headers: {
      // Authorization: localStorage.getItem("Authorization_OA_H5"),
      Authorization:localStorage.getItem("Authorization_OA_H5"),

      "User-Source": "APP_OA",
    },
    data,
  });
}
//上传图片
export function uploadFile(data) {
  return request({
    url: "/cdn/upload/uploadFile",
    method: "POST",
    headers: {
      // Authorization: localStorage.getItem("Authorization_OA_H5"),
      Authorization:localStorage.getItem("Authorization_OA_H5"),

      "User-Source": "APP_OA",
    },
    data,
  });
}
//会议纪要
export function updateMeeting(data) {
  return request({
    url: "/meeting/appointment/updateMeeting",
    method: "post",
    data,
  });
}
//获取地址--不根据地址
export function noAdderessMeetingRoomList(params) {
  return request({
    url: "/meeting/meetingRoomList",
    method: "GET",
    params,
  });
}
//获取会议室详情
export function MeetingRoomInfo(params) {
  return request({
    url: `/meeting/info/${params.mrCode}`,
    method: "GET",
    params,
  });
}
