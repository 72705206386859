<!--  -->
<template>
  <div class="ordered-meeting-room">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '已预约会议室',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <div class="tab-box">
        <van-tabs v-model="activeTab" @change="changeTab">
          <van-tab
            :title="tab.title"
            :name="tab.name"
            v-for="(tab, i) in tabs"
            :key="i"
          >
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-if="tableData.length > 0"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getList"
                class="list-box"
              >
                <van-cell v-for="(item, i) in tableData" :key="i" class="item">
                  <div class="layer-btn-name">
                    <span class="name">{{ item.mrSubject }}</span>
                    <span class="status-cancel" v-if="0 == item.status">
                      已取消
                    </span>
                    <span class="status-finish" v-if="3 == item.status">
                      已结束
                    </span>
                  </div>
                  <div class="layer-time" v-if="item.isEnd == 0">
                    时间：{{ item.beginTimeDesc + "~" + item.endTimeDesc }}
                  </div>
                  <div class="layer-time" v-if="item.isEnd == 1">
                    时间：{{ item.beginTimeDesc + "~" + item.subEndTime }}
                  </div>
                  <div class="layer-area">
                    地点：{{ item.mrAddress || "--" }}
                  </div>
                  <div class="buttons">
                    <div class="btn-opt-2" v-if="activeTab == '2'">
                      <span
                        class="end"
                        v-if="item.status == 2"
                        @click="handleEndMeeting(item)"
                        >结束会议</span
                      >
                      <span class="detail" @click="handleDetail(item)"
                        >会议详情</span
                      >
                      <span
                        @click="handleUnorder(item)"
                        class="cancel"
                        v-if="item.status == 1"
                        >取消预约</span
                      >
                      <span class="remark" @click="handleSetMeeting(item)"
                        >会议纪要</span
                      >
                    </div>
                    <div class="btn-opt-1" v-if="activeTab == '1'">
                      <span class="detail" @click="handleDetail(item)"
                        >会议详情</span
                      >
                      <span class="remark" @click="handleMeeting(item)"
                        >会议纪要</span
                      >
                    </div>
                  </div>
                </van-cell>
              </van-list>
              <van-empty v-else description="暂无数据"></van-empty>
            </van-pull-refresh>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- 详情 -->
    <van-popup
      v-if="roomDetail"
      round
      position="bottom"
      :style="{ minHeight: '40%' }"
      v-model="showDetail"
    >
      <div class="poup-box">
        <div class="title">会议详情</div>
        <div class="item-info">
          <p class="label">会议地点：</p>
          <p class="value">
            <van-icon name="location" /> {{ roomDetail.mrAddress || "--" }}
          </p>
        </div>
        <div class="item-info">
          <p class="label">会议时间：</p>
          <p class="value" v-if="roomDetail.isEnd == 0">
            <van-icon name="clock" />
            {{ roomDetail.beginTimeDesc }}~{{ roomDetail.endTimeDesc }}
          </p>
          <p class="value" v-else>
            <van-icon name="clock" />
            {{ roomDetail.beginTimeDesc }}~{{ roomDetail.subEndTime }}
          </p>
        </div>
        <div class="item-info">
          <p class="label">会议名称：</p>
          <p class="value">
            <van-icon name="star" /> {{ roomDetail.mrSubject || "--" }}
          </p>
        </div>
        <div class="item-info">
          <p class="label">会议描述：</p>
          <p class="value">
            <van-icon name="coupon" /> {{ roomDetail.mrDesc || "--" }}
          </p>
        </div>
        <div class="item-info">
          <p class="label">参会部门/人员:</p>
          <p class="value">
            <van-icon name="friends" />
            {{ roomDetail.partList | formatPartList }}
          </p>
        </div>
      </div>
    </van-popup>

    <!-- 纪要 -->
    <van-popup
      v-if="roomDetail"
      round
      position="bottom"
      :style="{ height: '40%' }"
      v-model="showRemark"
    >
      <div class="poup-box">
        <div class="title">{{ roomDetail.mrSubject }}</div>
        <div>
          <div class="item-info">
            <p class="label">会议纪要:</p>
            <p class="value">
              <van-icon name="comment" /> {{ roomDetail.mrSummary || "--" }}
            </p>
          </div>
          <div class="item-info">
            <p class="label">纪要附件:</p>
            <p class="value pointer" @click="openUrl(roomDetail.fileUrl)">
              <van-icon name="wap-nav" />
              {{ roomDetail.fileName || "--" }}
            </p>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 添加纪要 -->
    <van-popup
      v-if="showAddRemark"
      round
      position="bottom"
      :style="{ height: '60%' }"
      v-model="showAddRemark"
    >
      <div class="poup-box">
        <div class="title">添加纪要</div>
        <van-field
          class="input-text"
          v-model.trim="mrDesc"
          maxlength="5000"
          type="textarea"
          placeholder="请输入会议纪要"
        />
        <div class="item-info">
          <p class="label">会议附件:</p>
          <div class="value">
            <el-upload
              class="upload-img"
              action=""
              :file-list="fileList"
              :before-upload="beforeAvatarUpload"
              :multiple="false"
            >
              <el-button plain size="small" type="primary"> 上传附件</el-button>
            </el-upload>
          </div>
        </div>

        <div class="btn-opt">
          <p class="submit-btn" :loading="loading" @click="sureAddRemark">
            提交
          </p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
import {
  addMeetingAddress,
  meetingRoomList,
  uploadImg,
  addMeeting,
  addressList,
  delMeeting,
  editMeeting,
  noAdderessMeetingRoomList,
  MeetingRoomInfo,
  partMeetingList,
  myAppointList,
  cancleAppointment,
  endMeeting,
  appointmentInfo,
  uploadFile,
  updateMeeting,
} from "@/api/smartMeetingRoom/index.js";
import { Toast } from "vant";
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      activeTab: "1",
      tabs: [
        {
          name: "1",
          title: "已预定记录",
        },
        {
          name: "2",
          title: "我的预定",
        },
      ],
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      loading: false,
      finished: false,
      refreshing: false,
      tableTotal: 0,
      showDetail: false,
      roomDetail: null,
      showRemark: false,
      showAddRemark: false,
      fileList: [],
      mrDesc: "",
    };
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    this.getList();
  },
  filters: {
    formatPartList(val) {
      if (!val) {
        return "--";
      }
      if (!val.length) {
        return "--";
      }
      let partNameArr = val.map((item) => {
        return item.partName;
      });

      return partNameArr.join(",");
    },
  },
  methods: {
    changeTab() {
      this.initData();
    },
    initData() {
      this.finished = false; //清空类表数据
      this.loading = false; //加载状态
      this.refreshing = false;
      this.query.pageNum = 1;
      this.query.pageSize = 10;
      this.tableData = [];
      this.tableTotal = 0;
      this.getList();
    },
    getList() {
      if (this.activeTab == "1") {
        this.partMeeting();
      } else if (this.activeTab == "2") {
        this.getMymeeting();
      }
    },
    //获取我的会议室
    getMymeeting() {
      myAppointList(this.query).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.loading = false;
            this.refreshing = false;
            this.tableData = this.tableData.concat(data.records);
            this.tableTotal = data.total;
            if (this.tableTotal.length >= data.total) {
              //如果没有数据了，把finished设置为true，之后就不会触发加载更多
              this.finished = true;
            } else {
              if (this.query.pageNum >= data.pages) {
                //如果没有数据了，把finished设置为true，之后就不会触发加载更多
                this.finished = true;
              } else {
                this.query.pageNum++;
              }
            }
          }
        }
      });
    },
    partMeeting() {
      partMeetingList(this.query).then((res) => {
        this.loading = false;
        this.refreshing = false;
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.tableData = this.tableData.concat(data.records);
            this.tableTotal = data.total;
            if (this.tableTotal.length >= data.total) {
              //如果没有数据了，把finished设置为true，之后就不会触发加载更多
              this.finished = true;
            } else {
              if (this.query.pageNum >= data.pages) {
                //如果没有数据了，把finished设置为true，之后就不会触发加载更多
                this.finished = true;
              } else {
                this.query.pageNum++;
              }
            }
          }
        }
      });
    },
    onRefresh() {
      this.query.pageNum = 1;
      this.tableData = [];
      this.total = 0;
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
      this.getList();
    },
    //获取详情
    getInfo(name, code) {
      appointmentInfo({ appoCode: code }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.roomDetail = data;
            if (name == "detail") return (this.showDetail = true);
            if (name == "metting") return (this.showRemark = true);
            if (name == "addRemark") {
              this.showAddRemark = true;
              this.mrDesc = data.mrSummary;
              this.fileList = [];
              if (data.fileCode) {
                this.fileList = [
                  {
                    name: data.fileName,
                    code: data.fileCode,
                    url: data.fileUrl,
                  },
                ];
              }

              return;
            }
          }
        }
      });
    },
    //详情
    handleDetail(room) {
      this.getInfo("detail", room.appoCode);
    },
    //纪要
    handleMeeting(room) {
      this.getInfo("metting", room.appoCode);
    },
    openUrl(url) {
      if (!url) return Toast("未上传纪要附件");
      window.open(url);
    },
    //设置纪要
    handleSetMeeting(room) {
      this.getInfo("addRemark", room.appoCode);
    },
    beforeAvatarUpload(file) {
      // console.log(file);
      let types = [
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "image/jpeg",
        "image/png",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "application/vnd.ms-powerpoint",
        "application/msword",
      ];
      this.fileList = [];
      const isLt2M = file.size / 1024 / 1024 < 10;
      const isType = types.indexOf(file.type) > -1;
      if (!isType) {
        Toast("上传文件类型有误,支持xls/docx/png/jpg/txt/pptx/ppt/doc类型文件");
        return;
      }
      if (!isLt2M) {
        Toast("上传文件大小不能超过 10MB!");
        return;
      }
      const form = new FormData();
      form.append("file", file);
      uploadFile(form).then((res) => {
        if (res) {
          let { data, code, msg } = res;
          if (code == 200) {
            Toast(msg);
            this.fileList.push({
              name: file.name,
              code: data.fileCode,
              url: data.fileUrl,
            });
          }
        }
      });
    },

    sureAddRemark() {
      const str =
        this.fileList.length > 0
          ? this.fileList
              .map((e) => {
                return e.code;
              })
              .toString()
          : "";
      const params = {
        appoCode: this.roomDetail.appoCode,
        attachment: str,
        mrSummary: this.mrDesc,
      };
      updateMeeting(params)
        .then((res) => {
          if (res) {
            let { msg, code } = res;
            if (code == 200) {
              Toast(msg);
              this.showAddRemark = false;
              this.mrDesc = "";
              this.fileList = [];
              this.initData();
            }
          }
        })
        .catch((e) => {
          Toast("添加纪要失败");
        });
    },
    // 结束会议
    handleEndMeeting(room) {
      Dialog.confirm({
        title: "提示",
        message: "此操作将结束会议, 是否继续?",
        beforeClose: (action, done) => {
          if (action == "confirm") {
            endMeeting({ appoCode: room.appoCode })
              .then((res) => {
                if (res) {
                  let { code, msg } = res;
                  if (code == 200) {
                    Toast(msg);
                    this.initData();
                    done();
                  }
                }
              })
              .catch(() => {
                done();
              });
          } else {
            done();
          }
        },
      });
    },
    // 取消会议
    handleUnorder(room) {
      Dialog.confirm({
        title: "提示",
        message: "此操作将取消已预定的会议, 是否继续?",
        beforeClose: (action, done) => {
          if (action == "confirm") {
            cancleAppointment({ appoCode: room.appoCode })
              .then((res) => {
                if (res) {
                  let { code, msg } = res;
                  if (code == 200) {
                    Toast(msg);
                    this.initData();
                    done();
                  }
                }
              })
              .catch(() => {
                done();
              });
          } else {
            done();
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ordered-meeting-room {
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: relative;

  .list-box {
    width: 100%;
    padding: 0.3rem;
    .item {
      border-radius: 0.2rem;
      background-color: rgba(255, 255, 255, 1);
      margin-bottom: 0.2rem;
      width: 100%;
      .layer-btn-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .name {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }

        .status-cancel {
          color: #f56c6c;
          border-radius: 0.1rem;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.2rem;
          padding: 0 0.1rem;
          background: #fef0f0;
        }
        .status-finish {
          color: #409eff;
          border-radius: 0.1rem;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.2rem;
          padding: 0 0.1rem;
          background: #def2fe;
        }
      }

      .layer-time,
      .layer-area {
        height: 0.37rem;
        font-size: 0.26rem;
        font-weight: 400;
        color: #999999;
        line-height: 0.37rem;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        margin-top: 0.12rem;
      }

      .buttons {
        text-align: right;
        .btn-opt-1 {
          font-size: 0.26rem;
          font-weight: 400;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .cancel {
            color: #f65f38;
            margin-left: 0.2rem;
          }
          .end {
            color: #f56c6c;
            margin-left: 0.2rem;
          }
          .remark {
            color: #409eff;
            margin-left: 0.2rem;
          }
          .detail {
            color: #67c23a;
            margin-left: 0.2rem;
          }
        }
        .btn-opt-2 {
          font-size: 0.26rem;
          font-weight: 400;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .cancel {
            color: #f65f38;
            margin-left: 0.2rem;
          }
          .end {
            color: #f56c6c;
            margin-left: 0.2rem;
          }
          .remark {
            color: #409eff;
            margin-left: 0.2rem;
          }
          .detail {
            color: #67c23a;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }
  .poup-box {
    width: 100%;
    padding: 0.3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      width: 100%;
      font-size: 0.4rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0.4rem;
      flex-shrink: 0; //保持内容不缩放，适用于内容不确定大小时候
    }
    .item-info {
      margin-top: 0.2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .label {
        width: 1.5rem;
        font-size: 0.3rem;
      }
      .value {
        flex: 1;
        text-align: left;
        font-size: 0.26rem;
        font-weight: 400;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pointer {
        color: #409eff;
      }
    }
    .input-text {
      margin-top: 0.28rem;
      min-height: 4.09rem;
      background: #f4f9fb;
      border-radius: 0.16rem;
      flex-grow: 1; //充满屏幕
      /deep/.van-field__control {
        min-height: 4rem;
      }
    }
    .upload-img {
      margin-bottom: 0.28rem;
    }
    .btn-opt {
      width: 6.6rem;
      height: 0.8rem;
      flex-shrink: 0; //保持内容不缩放，适用于内容不确定大小时候
      .submit-btn {
        height: 0.8rem;

        background: #0094ee;
        border-radius: 0.4rem;
        font-weight: 400;
        color: #fff;
        font-size: 0.26rem;
        text-align: center;
        line-height: 0.8rem;
      }
    }
  }
}
</style>
